import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setFormData, resetFormData } from "../../../redux/reducer";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { formBuilder } from "../../../utils/formGenerator";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  Label,
  Field,
  Input,
  useId,
  ComboboxProps,
  Option,
  Dropdown,
  Select,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  EyeFilled,
  Add16Filled,
  EyeOffFilled,
} from "@fluentui/react-icons";
import SystemManagerTableDataList from "../SystemManagerTableDataList";
import apiServices from "../../../service";
import * as Yup from "yup";
import { checkUserPermission } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import "../style.css";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },

  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
type FormContainerWithListProps = {
  [x: string]: any;
};
const ManageUserFormContainer = (props: FormContainerWithListProps) => {
  let { context } = props;
  const classes = useStyles();
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [mode, setMode] = useState("");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [userInfo, setUserInfo] = useState<{ [key: string]: any }>({});
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const [initialFormData, setInitialFormData] = useState({});
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const [permissionList, setPermissionList] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const dispatch: AppDispatch = useDispatch();
  const styles = useStyles();
  const [listItems, setListItems] = useState<any>([]);
  const [selectedRolePermission, setSelectedRolePermissions] = useState<any[]>(
    []
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const comboId = useId("combo-multi");
  const [selectedDropDownValues, setSelectedDropDownValues] = useState<
    string[]
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onSelect: ComboboxProps["onOptionSelect"] = (event: any, data) => {
    setSelectedOptions(data.selectedOptions);
  };
  const handleOptionChange = (event: any, option: string | any) => {
    if (!option) return; // Handle potential edge cases
    dispatch(setFormData());
    const newSelectedValues = [...selectedDropDownValues];
    if (newSelectedValues.includes(option.optionValue)) {
      const index = newSelectedValues.indexOf(option.optionValue);
      newSelectedValues.splice(index, 1);
    } else newSelectedValues.push(option.optionValue);

    setSelectedDropDownValues(newSelectedValues);
  };

  const [currentRecord, setCurrentRecord] = useState(1);

  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };
  useEffect(() => {
    setPermissionList(props.permissionsList);
  }, [props.permissionsList]);

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
    if (JSON.stringify(formik.initialValues) === JSON.stringify(newPresent)) {
      dispatch(resetFormData());
      setFormMode("");
    } else {
      dispatch(setFormData());
    }
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  useEffect(() => {
    if (context !== "") {
      generateDynamicForm(context);
    }
  }, [context]);
  useEffect(() => {
    getUserListItem();
  }, []);
  useEffect(() => {
    if (props.dataList.length > 0) {
      setSelectedRowItem(props.dataList[0]);
      setMode("view");
      if (props.setSelectedUserData) {
        props.setSelectedUserData(props.dataList[0]);
      }
    }
  }, [props.dataList]);

  useEffect(() => {
    if (
      mode &&
      selectedRowItem &&
      ["view", "edit"].includes(mode) &&
      Object.keys(selectedRowItem).length !== 0
    ) {
      let rowItem = {};
      if (context === "SYSTEM_MANAGER_USERLIST") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
        getUserDetail(selectedRowItem);
      }
      setInitialFormData(rowItem);
    }
    if (mode === "add") {
      setFormField({
        full_name: "",
        short_name: "",
        active: "0",
        user_email: "",
        password: "",
        prefix: "",
        user_role: "",
        domain: loggedUser?.userData?.email
          ? getDomain(loggedUser.userData.email).domain
          : "",
      });
      setSelectedPermissions([]);
    } else {
      setFormField((prev) => ({
        ...prev,
        full_name: selectedRowItem.full_name,
        short_name: selectedRowItem.short_name,
        user_email: selectedRowItem.user_email,
        prefix: selectedRowItem.user_email
          ? getDomain(selectedRowItem.user_email).prefix
          : "",
        domain: selectedRowItem.user_email
          ? getDomain(selectedRowItem.user_email).domain
          : "",
        user_role: selectedRowItem.user_role,
        active: selectedRowItem.active,
      }));
    }
  }, [mode, selectedRowItem]);
  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings: objFormSettings?.systemAdmin,
    });
    let viewData = { ...initialValues };

    setFormFieldData({ initialValues, inputs, validationSchema });
  };
  const getDomain = (inputEmail: string) => {
    const atIndex = inputEmail.indexOf("@");
    if (atIndex !== -1) {
      const prefix = inputEmail.substring(0, atIndex);
      const domain = inputEmail.substring(atIndex + 1);

      return { prefix: prefix, domain: domain };
    } else {
      return { prefix: "", domain: "" };
    }
  };
  const getUserListItem = async () => {
    try {
      const response =
        await apiServices.systemManager.manageUsers.getuserList();
      if (response && response.data && response.data.data.length > 0) {
        let newArr = response.data.data.map((v: any, index: number) => ({
          ...v,
          id: v.user_id,
          order:
            v.keycloak_id !== "00000000-0000-0000-0000-000000000000"
              ? index + 1
              : 0,
        }));
        newArr.sort((a: any, b: any) => a.order - b.order);
        setListItems(newArr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserName = (userId: string) => {
    let obj: any = "";
    if (listItems.length > 0) {
      obj = listItems.find((user: any) => {
        return user.id === userId;
      });
    }

    return obj?.full_name || "";
  };
  const [formField, setFormField] = useState({
    full_name: "",
    short_name: "",
    active: "0",
    user_email: "",
    password: "",
    prefix: "",
    domain: "",
    user_role: "",
  });
  const addSchema = Yup.object().shape({
    full_name: Yup.string().max(
      20,
      "Full Name cannot be longer than 20 characters"
    ),
    short_name: Yup.string().max(
      20,
      "Short Name cannot be longer than 20 characters"
    ),
    emailPrefix: Yup.string()
      .required("Email is required")
      .max(50, "Email cannot be longer than 50 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .max(20, "Password cannot be longer than 20 characters"),
    user_role: Yup.string().required("Role is required"),
  });
  const updateSchema = Yup.object().shape({
    full_name: Yup.string().max(
      20,
      "Full Name cannot be longer than 20 characters"
    ),
    short_name: Yup.string().max(
      20,
      "Short Name cannot be longer than 20 characters"
    ),
    emailPrefix: Yup.string()
      .required("Email is required")
      .max(50, "Email cannot be longer than 50 characters"),
    user_role: Yup.string().required("Role is required"),
  });
  const formik = useFormik({
    initialValues: {
      full_name: formField?.full_name ? formField.full_name : "",
      short_name: formField?.short_name ? formField.short_name : "",
      password: formField?.password ? formField.password : "",
      emailPrefix: formField?.prefix ? formField.prefix : "",
      email: formField?.user_email ? formField.user_email : "",
      user_role: formField?.user_role ? formField.user_role : "",
      active: formField?.active ? formField.active : "0",
    },
    validationSchema: mode === "add" ? addSchema : updateSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  const permissionAdded = async (permission: any) => {
    let permArry = [];
    permArry.push(permission);

    setSelectedRolePermissions(permArry);
  };
  const formSubmitted = (values: any) => {
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    setFormError([]);
    let reqBody: { [key: string]: any } = {};

    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }

    if (mode === "add") {
      setFormError([]);
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "user_list") {
          addReq = {
            username: values.emailPrefix + "@" + formField.domain,
            enabled: parseInt(values.active) == 1 ? true : false,
            totp: false,
            emailVerified: true,
            firstName: values.full_name,
            lastName: values.short_name,
            email: values.emailPrefix + "@" + formField.domain,
            credentials: [
              {
                type: "password",
                value: values.password,
                temporary: false,
              },
            ],
            realmRoles: selectedRolePermission,
            limit_users: selectedDropDownValues,
            userRole: values.user_role,
            active: parseInt(values.active),
          };
          apiServices.systemManager.manageUsers
            .createUser(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        }
      }
    } else if (mode === "edit") {
      setFormError([]);
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "user_list") {
          updateReq = {
            user_email: values.emailPrefix + "@" + formField.domain,
            full_name: values.full_name,
            short_name: values.short_name,
            roles: selectedRolePermission,
            limit_users: selectedDropDownValues,
            user_role: values.user_role,
            active: parseInt(values.active),
          };
          if (reqBody.password !== undefined && reqBody.password !== "") {
            updateReq = {
              ...updateReq,
              credentials: [
                {
                  type: "password",
                  value: reqBody.password,
                  temporary: false,
                },
              ],
            };
          }

          apiServices.systemManager.manageUsers
            .updateUser(selectedRowItem.user_id, updateReq)

            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        }
      }
    }
  };
  const handleApiSuccessResponse = (response: any) => {
    if (!response.data.isError) {
      setTimeout(() => {
        props.notify(response.data.message, "", "success");
      }, 1000);
      // props.setFormUpdated(false);
      dispatch(resetFormData());

      props.reloadData();
    } else if (response.data.isError) {
      if (response.data.error) {
        if (Array.isArray(response.data.message)) {
          setFormError(response.data.message);
        } else {
          setFormError((prev) => [response.data.message]);
        }
      }
    } else {
      props.notify("Something went wrong", "", "error");
    }
  };
  const handleApiErrorResponse = (e: any) => {
    props.notify(e.message, "", "error");
  };
  const getUserDetail = (row: any) => {
    apiServices.systemManager.manageUsers
      .getDetail(row.user_id)
      .then((response) => {
        if (response.data && Object.keys(response.data).length !== 0) {
          setUserInfo(response.data);

          if (
            response.data.roles !== undefined &&
            response.data.roles.length > 0
          ) {
            setSelectedRolePermissions(response.data.roles);
          } else {
            setSelectedRolePermissions([]);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleOnChange = (event: any) => {
    const data: any = { ...formik.values };

    data[event.target.name] = event.target.value;
    let updated = false;
    updatePresent(data);
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      dispatch(resetFormData());
      updated = false;
    } else {
      dispatch(setFormData());
      updated = true;
    }
    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  const changeMode = (mode: string) => {
    setMode(mode);
  };
  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
    if (props.setSelectedUserData) {
      props.setSelectedUserData(row);
    }
  };
  useEffect(() => {
    if (mode === "edit" && selectedRowItem && selectedRowItem.limit_users) {
      setSelectedDropDownValues(selectedRowItem.limit_users);
    } else {
      setSelectedDropDownValues([]);
    }
  }, [mode, selectedRowItem]);

  return (
    <>
      {props.title !== undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Label size="large">{props.title}</Label>
        </div>
      )}
      {props.dataList && props.dataList.length === 0 && mode === "" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_ADD_USER
          ) && (
            <Button
              className="asc-button-primary"
              style={{ marginRight: 2, marginBottom: 6 }}
              appearance="primary"
              size="medium"
              icon={<Add16Filled />}
              onClick={() => {
                changeMode("add");
              }}
            >
              Add New
            </Button>
          )}
        </div>
      )}
      {mode && mode !== "" && (
        <>
          {["edit", "add"].includes(mode) && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>
              <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={
                    past !== undefined && past.length === 0 ? true : false
                  }
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                }}
                disabled={!isFormDataUpdated}
              >
                Reset All
              </Button>
            </div>
          )}
          {props.mode && props.mode === "edit" && (
            <Button
              appearance="transparent"
              size="medium"
              icon={<Delete16Regular />}
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    content_line_1: "Do you want to delete?",
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "No",
                      type: "primary",
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Yes",
                    },
                  })
                );
              }}
            ></Button>
          )}
          {mode && mode === "view" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                Record {currentRecord} of {props.dataList.length}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_ADD_USER
                ) && (
                  <Button
                    className="asc-button-primary"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    appearance="primary"
                    size="medium"
                    icon={<Add16Filled />}
                    onClick={() => {
                      changeMode("add");
                    }}
                  >
                    Add New
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_USER
                ) && (
                  <Button
                    style={{ marginRight: 2, marginBottom: 6 }}
                    icon={<Edit24Filled />}
                    size="medium"
                    onClick={() => {
                      changeMode("edit");
                    }}
                  >
                    Edit
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_DELETE_USER
                ) && (
                  <Button
                    icon={<Delete16Regular />}
                    style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                    size="medium"
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1: "Do you want to delete?",
                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "No",
                            type: "primary",
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Yes",
                          },
                        })
                      );
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          )}
        </>
      )}
      <div className="system_container">
        {mode && mode !== "" && formError && formError.length > 0 && (
          <FormErrorMessage
            errorMessages={formError}
            onDismiss={() => setFormError([])}
          />
        )}
        <div
          style={{
            backgroundColor: "#f9f9f9",
            padding: 10,
            minHeight: "45vh",
          }}
        >
          <form onSubmit={formik.handleSubmit} onChange={handleOnChange}>
            <div className="admin-container">
              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="Full Name"
                    orientation="horizontal"
                    validationState={formik.errors.full_name ? "error" : "none"}
                    validationMessage={`${
                      formik.errors.full_name !== undefined
                        ? formik.errors.full_name
                        : ""
                    }`}
                  >
                    <Input
                      appearance="filled-lighter"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="full_name"
                      placeholder=""
                      value={
                        formik?.values.full_name !== undefined
                          ? formik.values.full_name
                          : ""
                      }
                    />
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      Full Name
                    </Label>
                    <Label>{selectedRowItem?.full_name}</Label>
                  </div>
                )}
              </div>
              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="Short Name"
                    orientation="horizontal"
                    validationState={
                      formik.errors.short_name ? "error" : "none"
                    }
                    validationMessage={`${
                      formik.errors.short_name !== undefined
                        ? formik.errors.short_name
                        : ""
                    }`}
                  >
                    <Input
                      appearance="filled-lighter"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="short_name"
                      placeholder=""
                      value={
                        formik?.values.short_name !== undefined
                          ? formik.values.short_name
                          : ""
                      }
                    />
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      Short Name
                    </Label>
                    <Label>{selectedRowItem?.short_name}</Label>
                  </div>
                )}
              </div>

              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="Email"
                    orientation="horizontal"
                    validationState={
                      formik.errors.emailPrefix ? "error" : "none"
                    }
                    validationMessage={`${
                      formik.errors.emailPrefix !== undefined
                        ? formik.errors.emailPrefix
                        : ""
                    }`}
                  >
                    <Input
                      appearance="filled-lighter"
                      contentAfter={`@${formField.domain}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="emailPrefix"
                      placeholder=""
                      value={
                        formik?.values.emailPrefix !== undefined
                          ? formik.values.emailPrefix
                          : ""
                      }
                    />
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      Email
                    </Label>
                    <Label>{selectedRowItem?.user_email}</Label>
                  </div>
                )}
              </div>
              {mode === "view" ? (
                <div className={classes.fieldWrapper}>
                  <Label weight="semibold" size="medium">
                    Password
                  </Label>
                  <Label>******</Label>
                </div>
              ) : (
                <div style={{ width: "80%", padding: 6 }}>
                  {["add", "edit"].includes(mode) && (
                    <Field
                      label="Password"
                      orientation="horizontal"
                      validationState={
                        formik.errors.password ? "error" : "none"
                      }
                      validationMessage={`${
                        formik.errors.password !== undefined
                          ? formik.errors.password
                          : ""
                      }`}
                    >
                      <Input
                        appearance="filled-lighter"
                        onChange={formik.handleChange}
                        type={isPasswordVisible ? "text" : "password"}
                        onBlur={formik.handleBlur}
                        name="password"
                        placeholder=""
                        value={
                          formik?.values.password !== undefined
                            ? formik.values.password
                            : ""
                        }
                        contentAfter={
                          <span
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {isPasswordVisible ? (
                              <EyeOffFilled />
                            ) : (
                              <EyeFilled />
                            )}
                          </span>
                        }
                      />
                    </Field>
                  )}
                </div>
              )}
              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="IsActive"
                    orientation="horizontal"
                    validationState={formik.errors.active ? "error" : "none"}
                    validationMessage={`${
                      formik.errors.active !== undefined
                        ? formik.errors.active
                        : ""
                    }`}
                  >
                    <Select
                      appearance="filled-lighter"
                      onBlur={formik.handleBlur}
                      name="active"
                      onChange={(e: any, data: any) => {
                        formik.setFieldValue("active", data.value);
                      }}
                      value={
                        formik?.values?.active !== undefined
                          ? formik.values.active
                          : ""
                      }
                    >
                      <option key={"1"} value="1">
                        Yes
                      </option>
                      <option key={"0"} value="0">
                        No
                      </option>
                    </Select>
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      IsActive
                    </Label>
                    <Label>{selectedRowItem?.active == 1 ? "Yes" : "No"}</Label>
                  </div>
                )}
              </div>
              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="Limit the User to this consultant list"
                    orientation="horizontal"
                    id={comboId}
                  >
                    <Dropdown
                      appearance="filled-lighter"
                      aria-labelledby={comboId}
                      multiselect={true}
                      placeholder={
                        selectedDropDownValues.length > 0
                          ? selectedDropDownValues
                              .map((userId) => getUserName(userId))
                              .join(", ")
                          : "Choose Limit the User to this consultant list"
                      }
                      selectedOptions={selectedDropDownValues}
                      onOptionSelect={handleOptionChange}
                    >
                      {listItems.map((option: any) => {
                        return (
                          <Option key={option.user_id} value={option.user_id}>
                            {option.full_name}
                          </Option>
                        );
                      })}
                    </Dropdown>
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      Limit the User to this consultant list
                    </Label>
                    {selectedRowItem?.limit_users &&
                      selectedRowItem.limit_users.length > 0 && (
                        <>
                          <Label>
                            {selectedRowItem.limit_users.map(
                              (item: any, index: number) => (
                                <span key={index}>
                                  {index <
                                  selectedRowItem.limit_users.length - 1
                                    ? `${getUserName(item)}, `
                                    : getUserName(item)}
                                </span>
                              )
                            )}
                          </Label>
                        </>
                      )}
                  </div>
                )}
              </div>

              <div style={{ width: "80%", padding: 6 }}>
                {mode !== "view" ? (
                  <Field
                    label="Role"
                    orientation="horizontal"
                    validationState={formik.errors.user_role ? "error" : "none"}
                    validationMessage={`${
                      formik.errors.user_role !== undefined
                        ? formik.errors.user_role
                        : ""
                    }`}
                  >
                    <Select
                      appearance="filled-lighter"
                      onBlur={formik.handleBlur}
                      name="user_role"
                      onChange={(e: any, data: any) => {
                        formik.setFieldValue("user_role", data.value);
                        let findRole = props.compositeRoles.find(
                          (item: any) => item.description === data.value
                        );
                        if (findRole) {
                          permissionAdded(findRole);
                        }
                      }}
                      value={
                        formik?.values?.user_role !== undefined
                          ? formik.values.user_role
                          : ""
                      }
                    >
                      <option key={""} value=""></option>
                      {props.compositeRoles.length > 0 &&
                        props.compositeRoles.map((role: any) => (
                          <option key={role.id} value={role.description}>
                            {role.description}
                          </option>
                        ))}
                    </Select>
                  </Field>
                ) : (
                  <div className={classes.fieldWrapper}>
                    <Label weight="semibold" size="medium">
                      Role
                    </Label>
                    <Label>{selectedRowItem?.user_role}</Label>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        {mode !== "view" && (
          <div className={classes.buttonWrapper}>
            <Button
              className="asc-button-primary"
              appearance="primary"
              disabled={!isFormDataUpdated}
              onClick={
                () => (isFormDataUpdated ? formik.handleSubmit() : "")
                // formik.handleSubmit();
              }
            >
              Save
            </Button>

            <Button
              onClick={() => {
                if (isFormDataUpdated === true) {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1:
                        "We have detected an attempt to move away from the current page.",
                      content_line_2:
                        "This would cause all changes made to be lost",
                      cancel: {
                        onclick: () => {
                          dispatch(
                            setDialogModalOptions({
                              open: false,
                            })
                          );
                          // props.setFormUpdated(false);
                          dispatch(resetFormData());
                          formik.resetForm();
                          changeMode("view");
                        },
                        label: "Leave the Page",
                      },
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Stay on Page",
                        type: "primary",
                      },
                    })
                  );
                } else {
                  formik.resetForm();
                  props.dataList.length === 0
                    ? changeMode("")
                    : changeMode("view");
                }
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        {props.dataList && props.dataList.length > 0 ? (
          <div>
            <SystemManagerTableDataList
              {...{
                changeMode,
                handleRowAction,
                mode,
                context: props.context,
                data: props.dataList,
                selectedRowItem,
                setCurrentRecord,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            {mode === "" && (
              <>
                {" "}
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ManageUserFormContainer;
